import React from "react";
import ReactDOM from "react-dom/client";
import "./font/stylesheet.css";
import "./index.css";
import "./App.css";
import "./page.module.css";
import "./globals.css";
import "./responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { AlertProvider } from "./context/AlerterContext";
import { GlobalDataProvider } from "./context/context";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  typography: {
    fontFamily: ["AvertaStd", "Arial", "sans-serif"].join(","),
  },
});

root.render(
  <AlertProvider>
    <GlobalDataProvider>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </GlobalDataProvider>
  </AlertProvider>
);
