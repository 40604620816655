import { useEffect } from "react";
import Lenis from "lenis";
import "lenis/dist/lenis.css";
const SmoothScroll = () => {
  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis({
      lerp: 0.07, // smoothness factor (tweak it for smoother or faster scroll)
      duration: 1.5,
      smoothWheel: true, // enable smooth scrolling with mouse wheel
      smoothTouch: true, // enable smooth scrolling on touch devices
    });

    // Use requestAnimationFrame to continuously update the scroll
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    // Start the animation frame loop
    requestAnimationFrame(raf);

    // Cleanup the Lenis instance on component unmount
    return () => {
      cancelAnimationFrame(raf);
      lenis.destroy(); // Proper cleanup
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return null; // Or return your component JSX if necessary
};

export default SmoothScroll;
