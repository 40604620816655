"use client";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "aos/dist/aos.css";
import Aos from "aos";
import { PiCaretLeftThin, PiCaretRightThin } from "react-icons/pi";
import { Link } from "react-router-dom";
import { BASE_URL } from "../context/UseApi";
import axios from "axios"; // Make sure to import axios

export const AssureSlide = ({ Data = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [banners, setBanners] = useState([]);
  const sliderRef = useRef(null);

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplaySpeed: 5000,
    autoplay: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <div className="home_banner position-relative">
        <div
          className="banner_slider"
          data-aos="fade-zoom-in"
          data-aos-delay={100}
          data-aos-duration={120}
          data-aos-once="true"
        >
          <Slider {...settings} ref={sliderRef}>
            {Data.map((banner, index) => (
              <div key={index}>
                <div className="banner_slide">
                  <Link to={banner.banner_url}>
                    <img
                      className="d-none d-sm-block"
                      src={banner.desktop_banner}
                      alt={banner.alt_text || "Banner Image"}
                      width={1270}
                      height={780}
                      loading="lazy"
                    />
                    <img
                      className="d-sm-none m_home_banner"
                      src={banner.mobile_banner}
                      alt={banner.alt_text || "Banner Image"}
                      width={630}
                      height={780}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
          <div className="currentslide flex-center">
            <button
              className="slider_count left flex-center "
              onClick={goToPrevSlide}
            >
              <PiCaretLeftThin />
            </button>
            <button
              className="slider_count  flex-center"
              onClick={goToNextSlide}
            >
              <PiCaretRightThin />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
