import React from 'react'

export const Diabetes = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 500 500"
    {...props}
  >
    <defs>
      <style>{".cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="M234.8 450.78c-12.36-1.51-24.68-3.26-36.69-6.71-53.72-15.41-94.36-47.6-121.91-96.16-19.4-34.2-27.22-71.32-24.64-110.41 2.95-44.65 19.12-84.17 48.53-118 27.03-31.11 60.46-52.06 100.29-62.64 10.3-2.73 20.75-4.7 31.39-5.57.76-.06 1.5-.34 2.26-.51h34.31c11.17 1.59 22.33 3.1 33.28 5.97 24.43 6.39 46.77 17.13 67.2 31.93 1.37.99 2.82 1.95 3.6 3.51 1.37 2.73 1.17 5.39-1.14 7.49-2.56 2.32-5.46 2.29-8.21.32-6.35-4.54-12.8-8.92-19.6-12.76-25.92-14.62-53.67-22.74-83.44-23.92-53.82-2.15-100.39 15.33-139.18 52.59-30.63 29.43-49.17 65.54-55.07 107.76-6.54 46.82 2.46 90.59 28.66 130.01 29.9 44.99 71.65 72.85 125.07 82.08 41.1 7.1 80.68 1.57 117.54-18.11 54.38-29.04 88.33-74.05 98.6-135.15 10.47-62.32-7.03-116.83-50.29-162.93-.53-.57-1.1-1.11-1.61-1.7-2.73-3.15-2.86-6.5-.37-9.1 2.54-2.65 6.19-2.56 9.33.45 5.55 5.32 10.6 11.1 15.34 17.14 24.39 31.1 38.64 66.29 42.25 105.73.07.77.32 1.52.49 2.28v32.81c-.16.63-.41 1.25-.47 1.88-2.36 27.76-10.12 53.94-23.79 78.16-28.11 49.79-69.7 82.49-124.92 97.56-11.17 3.05-22.61 4.55-34.06 5.99H234.8Z"
      className="cls-1"
    />
    <path
      d="M251.01 400.22c-82.11-.06-149.01-67.19-148.98-149.51.02-82.46 67.13-149.5 149.5-149.37 82.1.13 148.88 67.32 148.79 149.69-.09 82.26-67.14 149.25-149.31 149.18Zm.13-12.77c75.07.69 136.45-61.9 136.59-136.44.13-74.97-61.66-137.05-136.52-137.05-74.64 0-136.48 61.66-136.58 136.59-.11 75.27 61.87 137.57 136.52 136.9Z"
      className="cls-1"
    />
    <path
      d="M218.59 178.69c2.74-3.94 4.74-6.9 6.83-9.8 4.48-6.23 8.83-12.56 13.53-18.62 6.51-8.39 18.56-8.83 24.95-.37 23.35 30.95 44.34 63.29 56.34 100.64 1.75 5.45 3.23 10.99 3.89 16.7.44 3.83-1.53 6.68-4.84 7.3-3.41.64-6.63-1.23-7.31-4.85-5.46-29.12-19.5-54.43-34.9-79.14-6.61-10.6-13.84-20.79-21.33-30.78-3.25-4.34-5.17-4.34-8.32.03-5.32 7.39-10.59 14.81-15.79 22.28-2.37 3.41-2.21 3.66 1.64 4.3 9.09 1.51 15.46 8.92 14.78 17.18-.74 9.08-7.81 15.39-17.34 15.45-2.73.02-5.47.19-8.18-.04-3.21-.27-4.13 1.15-3.95 4.11.17 2.85.08 5.73.02 8.59-.22 10.51-8.25 17.8-18.65 16.83-1.65-.15-3.02-.48-3.71 1.59-5.78 17.38-8.4 34.83-2.18 52.76 8.09 23.33 31.23 42.88 60.96 41.57 24.37-1.07 47.98-19.84 55.16-44.8.43-1.5.73-3.04 1.2-4.53 1.29-4.16 4.35-6.16 7.81-5.17 3.53 1.01 5.21 4.22 4.29 8.37-2.6 11.78-7.65 22.43-15.37 31.65-15.59 18.62-35.66 28.09-59.91 27.06-33.86-1.44-62.65-27.35-69.01-60.8-3.32-17.49-.19-34.15 5.38-50.59 1.35-3.99 2.25-7.73 1.24-12.1-.83-3.59-.32-7.51-.26-11.28.03-2.32-.88-3.3-3.23-3.23-3.37.1-6.76.07-10.13 0-8.57-.19-15.39-6.84-16.02-15.57-.59-8.05 5.82-15.72 14.11-16.99 3.62-.55 7.26-.6 10.89-.4 3.48.19 4.55-1.24 4.44-4.58-.12-3.75-.42-7.54.39-11.29 1.87-8.67 9.06-14.04 17.91-13.34 7.9.63 14.33 7.65 14.7 16.1.07 1.52 0 3.04 0 5.77Zm-20.32 43.93h.04c0 2.73.01 5.47 0 8.2-.01 2.56.44 4.85 3.62 4.93 3.43.08 3.91-2.31 3.91-5.03 0-5.6-.1-11.2.06-16.79.13-4.88 2.48-7.09 7.31-7.15 5.72-.07 11.44.05 17.15-.1 2.46-.06 4.89-.89 4.88-3.92 0-3.2-2.39-4.03-5.11-4.05-5.33-.04-10.65 0-15.98-.03-5.95-.04-8.18-2.24-8.28-8.29-.09-5.34-.05-10.68-.03-16.01 0-2.64-.7-4.78-3.82-4.74-3.14.04-3.72 2.25-3.71 4.85.02 4.95.01 9.89 0 14.84-.02 7.38-1.94 9.33-9.16 9.35-4.81.01-9.62 0-14.42 0-2.76 0-5.64.34-5.77 3.75-.14 3.65 2.82 4.25 5.73 4.28 5.46.06 10.92-.02 16.37.05 4.64.06 6.96 2.24 7.19 6.86.15 2.99.03 5.99.03 8.98Z"
      className="cls-1"
    />
  </svg>
    </>
  )
}
