import React from "react";

export const AssureSafety = (props) => {
  return (
    <>
      <svg
        viewBox="0 0 24 30"
        xmlSpace="preserve"
        style={{}}
        x="0px"
        y="0px"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit={2}
        {...props}
      >
        <g>
          <path d="M22.75,12c0,-5.933 -4.817,-10.75 -10.75,-10.75c-5.933,0 -10.75,4.817 -10.75,10.75c0,5.933 4.817,10.75 10.75,10.75c0.414,0 0.75,-0.336 0.75,-0.75c0,-0.414 -0.336,-0.75 -0.75,-0.75c-5.105,0 -9.25,-4.145 -9.25,-9.25c0,-5.105 4.145,-9.25 9.25,-9.25c5.105,0 9.25,4.145 9.25,9.25c0,0.414 0.336,0.75 0.75,0.75c0.414,0 0.75,-0.336 0.75,-0.75Z" />
          <path d="M11.75,6.5l-0,4.689c0,0 -3.78,3.781 -3.78,3.781c-0.293,0.292 -0.293,0.768 -0,1.06c0.292,0.293 0.768,0.293 1.06,0l4,-4c0.141,-0.14 0.22,-0.331 0.22,-0.53l-0,-5c-0,-0.414 -0.336,-0.75 -0.75,-0.75c-0.414,0 -0.75,0.336 -0.75,0.75Z" />
          <path d="M17.5,12.25c-2.898,0 -5.25,2.352 -5.25,5.25c0,2.898 2.352,5.25 5.25,5.25c2.898,0 5.25,-2.352 5.25,-5.25c0,-2.898 -2.352,-5.25 -5.25,-5.25Zm-0,1.5c2.07,0 3.75,1.68 3.75,3.75c0,2.07 -1.68,3.75 -3.75,3.75c-2.07,0 -3.75,-1.68 -3.75,-3.75c0,-2.07 1.68,-3.75 3.75,-3.75Z" />
          <path d="M14.97,17.78l1.5,1.5c0.293,0.293 0.767,0.293 1.06,0l2.5,-2.5c0.293,-0.292 0.293,-0.768 0,-1.06c-0.292,-0.293 -0.768,-0.293 -1.06,-0l-1.97,1.969c-0,0 -0.97,-0.969 -0.97,-0.969c-0.292,-0.293 -0.768,-0.293 -1.06,-0c-0.293,0.292 -0.293,0.768 -0,1.06Z" />
        </g>
      </svg>
    </>
  );
};
