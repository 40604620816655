import React from "react";

const Sucess = (props) => {
  return (
    <div>
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 2544.15 2544.15"
          {...props}
        >
          <defs>
            <style>
              {
                "\n.cls-1{fill:#c9c9f2;}.cls-2{fill:#f5f5f5;}.cls-3{isolation:isolate;}.cls-4{stroke:#575bcf;}.cls-4,.cls-5{stroke-linecap:square;}.cls-4,.cls-5,.cls-6,.cls-7{fill:none;}.cls-4,.cls-5,.cls-7{stroke-miterlimit:10;}.cls-4,.cls-7{stroke-width:59.15px;}.cls-8{clip-path:url(#clippath);}.cls-5{stroke:#d4d4d4;stroke-width:43.91px;}.cls-9{fill:#3636cf;mix-blend-mode:multiply;opacity:.19;}.cls-7{stroke:#bac7eb;stroke-linecap:round;}"
              }
            </style>
            <clipPath id="clippath">
              <path
                className="cls-6"
                d="M2246.8,953.74c2.73,34.22-1.91,64.61-6.53,95.81-1.47,9.95-3.34,19.79-5.61,29.5-1.02,4.37-2.04,8.66-3.06,12.96-13.14,55.5-38.69,106.03-75.65,146.97-1.18,1.31-2.37,2.61-3.58,3.9-35.47,37.59-74.14,71.29-120.83,96.51-46.15,27.73-95.83,50.77-147.47,73.59-6.61,2.92-13.11,6.07-19.49,9.4-20.43,10.68-39.52,20.83-56.96,33.58-71.76,47.31-111.58,117.92-136.6,197.22-12.51,39.65-21.41,81.4-28.97,123.37-.7,329.93-308.74,571.27-645.01,504.49-13.27-3.14-24.18-8.53-37.01-13.1-41.02-16.4-78.36-39.32-111.56-70.2-56.13-53.01-88.47-116.62-112.17-184.49-7.9-22.62-14.84-45.71-21.38-69.04-19.05-69.61-34.67-143.97-88.18-198.71-80.26-82.1-183.95-120.75-279.8-187.59-151.75-105.63-141.29-305.48-32.97-406.97,16.99-17.09,34.06-31.73,51.56-47.82,90.63-76.59,214.27-83.67,331.93-109.39,69.67-16.79,131.88-52.86,159.39-120.43,59.32-138.19,98.55-279.15,193.43-390.16,13.13-16.76,26.9-32.95,41.34-48.4,43.32-46.33,92.59-85.91,148.12-113.96,22.21-11.22,45.43-20.59,69.66-27.81,261.23-89.02,601.29,3.56,776.48,272.4,31.85,48.88,61.78,98.57,85.94,150.68,19.46,42.88,37.44,85.13,51.65,131.45,3.9,14.55,8.23,27.67,11.88,42.18,6.05,23.6,9.94,45.13,11.45,64.05ZM885.48,579.19c.09-37.8-13.18-71.01-40.17-90.5-27-19.49-62.79-21.5-98.46-9.15-35.67,12.34-70.83,38.9-96.63,76.55-25.8,37.65-37.46,79.31-35.84,116.39,1.62,37.08,16.29,69.67,43.28,89.14s61.19,22.14,95.03,10.56c33.84-11.58,67.59-37.51,93.54-75.2,25.95-37.68,39.17-79.99,39.26-117.79Zm1050.26,985.61c-74.17-7.43-155.53,29.74-182.58,94.9-11.87,29.14-11.31,61.02,4.5,88.67,15.82,27.65,43.94,45.85,76.74,53.01,32.79,7.16,70.45,3.13,105.82-14.57,66.77-32.97,109.14-110.95,77.46-173.67-15.67-28.34-46.2-44.39-81.95-48.34Z"
              />
            </clipPath>
          </defs>
          <g className="cls-3">
            <g id="Layer_2">
              <g>
                <path
                  className="cls-1"
                  d="M2246.8,953.74c2.73,34.22-1.91,64.61-6.53,95.81-1.47,9.95-3.34,19.79-5.61,29.5-1.02,4.37-2.04,8.66-3.06,12.96-13.14,55.5-38.69,106.03-75.65,146.97-1.18,1.31-2.37,2.61-3.58,3.9-35.47,37.59-74.14,71.29-120.83,96.51-46.15,27.73-95.83,50.77-147.47,73.59-6.61,2.92-13.11,6.07-19.49,9.4-20.43,10.68-39.52,20.83-56.96,33.58-71.76,47.31-111.58,117.92-136.6,197.22-12.51,39.65-21.41,81.4-28.97,123.37-.7,329.93-308.74,571.27-645.01,504.49-13.27-3.14-24.18-8.53-37.01-13.1-41.02-16.4-78.36-39.32-111.56-70.2-56.13-53.01-88.47-116.62-112.17-184.49-7.9-22.62-14.84-45.71-21.38-69.04-19.05-69.61-34.67-143.97-88.18-198.71-80.26-82.1-183.95-120.75-279.8-187.59-151.75-105.63-141.29-305.48-32.97-406.97,16.99-17.09,34.06-31.73,51.56-47.82,90.63-76.59,214.27-83.67,331.93-109.39,69.67-16.79,131.88-52.86,159.39-120.43,59.32-138.19,98.55-279.15,193.43-390.16,13.13-16.76,26.9-32.95,41.34-48.4,43.32-46.33,92.59-85.91,148.12-113.96,22.21-11.22,45.43-20.59,69.66-27.81,261.23-89.02,601.29,3.56,776.48,272.4,31.85,48.88,61.78,98.57,85.94,150.68,19.46,42.88,37.44,85.13,51.65,131.45,3.9,14.55,8.23,27.67,11.88,42.18,6.05,23.6,9.94,45.13,11.45,64.05Z"
                />
                <g>
                  <path
                    className="cls-1"
                    d="M846.22,696.98c-25.95,37.68-59.7,63.61-93.54,75.2-33.84,11.58-68.05,8.91-95.03-10.56s-41.65-52.06-43.28-89.14c-1.62-37.08,10.05-78.74,35.84-116.39,25.8-37.65,60.96-64.2,96.63-76.55,35.67-12.34,71.46-10.34,98.46,9.15,27,19.49,40.26,52.7,40.17,90.5-.09,37.8-13.31,80.1-39.26,117.79Z"
                  />
                  <path
                    className="cls-1"
                    d="M2017.69,1613.13c31.68,62.72-10.7,140.71-77.46,173.67-35.37,17.69-73.03,21.72-105.82,14.57-32.79-7.16-60.92-25.37-76.74-53.01-15.82-27.65-16.38-59.52-4.5-88.67,27.04-65.15,108.4-102.32,182.58-94.9,35.75,3.95,66.28,19.99,81.95,48.34Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </>
    </div>
  );
};

export default Sucess;
