import React from 'react'

export const Call = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 230.91 223.35"
    {...props}
  >
    <path
      d="M211.52 84.67C200 47.94 175.74 24.11 138.4 14.86 83.34 1.22 27.89 37.7 17.52 93.59 7.55 147.37 42.59 199.07 96.24 209.7c3.51.71 7.08 1.12 10.62 1.67h17.41c7.25-.97 14.49-2.04 21.42-4.45 36.82-12.81 59.4-38.31 67.81-76.36.76-3.41 1.05-6.93 1.57-10.41v-17c-.87-6.23-1.67-12.46-3.56-18.48Zm-91.35-28.63q0-3.64 3.56-3.41c25.69 1.61 46.95 21.33 50.64 46.96.29 1.97.37 3.99.59 5.97.11 1.15-.37 1.69-1.52 1.68-.13 0-.28.01-.41.01-6.77-.08-5.72.85-6.4-5.43-2.35-21.73-20.77-39.45-42.7-41.15q-3.76-.29-3.76-4.01v-.63Zm3.41 17.85c15.54 1.65 28.38 14.53 29.95 30.03.31 3.12.07 3.33-3.89 3.32-.68 0-1.37-.01-2.07.01-1.37.05-1.91-.55-2.05-1.96-1.29-12.69-10.66-22.04-23.48-23.41-1.28-.15-1.89-.63-1.87-1.91.01-1.04-.01-2.08-.01-3.11.01-3 .37-3.31 3.41-2.99Zm49.44 76.74c-6.73 7.59-14.56 13.93-23.02 19.49-1.48.97-2.19 1.13-3.55 1.08-7.13-.64-14.12-2.88-20.68-6.17-29.49-14.82-51.51-36.98-65.07-67.24-2.28-5.11-3.8-10.5-4.45-16.12-.16-1.39-.01-2.61.72-3.75 5.75-8.85 12.32-17.01 20.3-23.94 2.6-2.25 4.21-2.17 6.31.56 6.35 8.32 11.78 17.21 16.06 26.77.93 2.08.52 3.89-1.21 5.41-2.75 2.41-5.44 4.89-8.21 7.28-1.35 1.15-1.59 2.36-1.03 4.03 5.32 15.96 15.44 27.85 30.14 35.91a54.77 54.77 0 0 0 10.29 4.35c1.52.47 2.63.23 3.67-.99 2.35-2.72 4.8-5.35 7.13-8.06 1.73-2.03 3.73-2.43 6.12-1.33 9.2 4.21 17.8 9.48 25.89 15.53 3.31 2.47 3.36 4.05.59 7.2Z"
      style={{
        fill: "#5ed7b4",
        strokeWidth: 0,
      }}
    />
  </svg>
    </>
  )
}
