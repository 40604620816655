import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Dots } from "./components/svg-components/Dots";
import { Line } from "./components/svg-components/Line";
import { Testimonials } from "./components/Testimonials";
import { Faq } from "./components/Faq";
import WhyUs from "./components/WhyUs";
import Meta from "./components/Meta/index";
import BgGradientLayer from "./images/gradient-layer.webp";
import { BASE_URL } from "./context/UseApi";
import axios from "axios"; // Make sure to import axios
import { TestCardLandingPage } from "./components/TestCardLandingPage";
import LandinPageForm from "./components/LandinPageForm";

const LandingPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Ensure navigate is used for redirection

  useEffect(() => {
    Aos.init();

    setIsLoading(true);
    axios
      .get(`${BASE_URL}/campaigns.php?campaign=${slug}`)
      .then((response) => {
        console.log("This is the data: ", response.data);
        if (response.data.message === "No data found") {
          navigate("/"); // Use navigate for redirection
        } else {
          setData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [slug, navigate]); // Add dependencies to useEffect

  // Handle loading state
  if (isLoading) {
    return (
      <div className="_loader_cnt col-12 d-flex justify-content-center">
        <div className="_loader"></div>
      </div>
    );
  }

  // Assuming PageData is derived from data, ensure it's properly set
  const PageData = data; // Example assignment, adjust as needed

  if (!PageData || !PageData.sections) {
    return <div>Loading...</div>;
  }

  const { meta, bannerImages, Title, sections, FaqSlug } = PageData;
  console.log("faqSlug".PageData);

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{data.meta.title}</title>
        <meta name="description" content={data.meta.description} />
        <link rel="canonical" href={data.meta.canonical} />
        <meta httpEquiv="Content-Language" content={data.meta.language} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={data.meta.title} />
        <meta property="og:description" content={data.meta.description} />
        <meta
          property="og:street_address"
          content={data.meta.og.street_address}
        />
        <meta property="og:region" content={data.meta.og.region} />
        <meta property="og:postal_code" content={data.meta.og.postal_code} />
        <meta property="og:country_name" content={data.meta.og.country_name} />
        <meta property="og:email" content={data.meta.og.email} />
        <meta property="og:locale" content={data.meta.og.locale} />
        <meta
          property="og:locale:alternate"
          content={data.meta.og.locale_alternate}
        />
      </Helmet>

      <main className="d-flex flex-wrap float-start col-12">
        <section className="position-relative __about__">
          <div className="container">
            <div className="row">
              <div
                className="col-12 abt_banner"
                data-aos="fade-in"
                data-aos-duration={600}
                data-aos-once="true"
                data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in"
              >
                <img
                  src={bannerImages.desktop}
                  alt="Comprehensive Health Checkup"
                  width={1220}
                  height={730}
                  className="desktop-show d-none d-sm-block h-auto"
                />
                <img
                  src={bannerImages.mobile}
                  className="mobile-show d-block d-sm-none"
                  alt="Comprehensive Health Checkup"
                />
              </div>
              <div className="landingPageFrom">
                <LandinPageForm Slug={slug} />
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative mb-5 ">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div className="title col-12 float-start text-center mb-5">
                  <h1 className="text-uppercase">{Title}</h1>
                </div>
                <div className=" flex-center flex-wrap  gap-5 ">
                  {sections.test_data.map((test, index) => (
                    <TestCardLandingPage
                      key={index}
                      Slug={test.Slug}
                      Test_Name={test.Test_Name}
                      Test_Amount={test.Test_Amount}
                      Discount_Amount={test.Discount_Amount}
                      Test_Category={test.Test_Category}
                      Test_ID={test.Test_ID}
                      Test_Description={test.Test_Description}
                      Who_is_it_for={test.Who_is_it_for}
                      Pre_test_information={test.Pre_test_information}
                      Turn_around_time={test.Turn_around_time}
                      BaseDirectory={"packages"}
                      index={index}
                      IsSeoH3={true}
                      NoOfTEst={test.TestInfo.length}
                    />
                  ))}
                </div>
                <Dots className="hsection position-absolute svgwidth opacity-10 end-0 left-inherit" />
                <Line className="svgwidthline position-absolute opacity-10 top-20 start-0" />
              </div>
            </div>
          </div>
          <div className="bg-gradient-image position-absolute landingPageBgImage ">
            <img
              src={BgGradientLayer}
              alt="gradient file"
              width={"1920px"}
              height={"150vh"}
            />
          </div>
        </section>

        <section className="position-relative lp  ">
          <WhyUs Title="WHY ASSURE PATHLABS?" />

          <div className="testimonial lp">
            <div className=" position-relative z-index-2">
              <div className="">
                <div className="row">
                  <div
                    className="title col-12 float-start text-center"
                    data-aos="fade-up"
                    data-aos-duration={500}
                    data-aos-once="true"
                    data-aos-easing="ease-in"
                  >
                    <h2 className="">TESTIMONIALS</h2>
                  </div>
                  <div className=" col-12 m-auto float-start">
                    <Testimonials className="minusbottom navigationwhite" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dots className="hsection position-absolute svgwidth opacity-10 start-0 bottom-0 top-inherit" />
          <Line className="svgwidthline position-absolute opacity-10 bottom-0 end-0" />
        </section>
        <section id="faq" className="faq">
          <div className="container position-relative z-index-2">
            <div className="web-container">
              <div
                className="row"
                data-aos="fade-up"
                data-aos-duration={500}
                data-aos-once="true"
                data-aos-easing="ease-in"
              >
                <Faq className="minusbottom " slug={sections.FaqSlug} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default LandingPage;
