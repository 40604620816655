import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 128 128"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="m34.43 24.81 3.07 5.27a2 2 0 0 0 3.46-2l-4.07-7a2 2 0 0 0-2.72-.73l-7 4a2 2 0 0 0 2 3.47zM43.44 96.85a2 2 0 0 0 2.72.73l7-4a2 2 0 0 0-2-3.47l-5.26 3.01-3.07-5.27a2 2 0 0 0-3.46 2zM16.51 30.49l-7 4a2 2 0 0 0-.73 2.74l4.07 7a2 2 0 0 0 3.46-2L13.2 37l5.29-3a2 2 0 1 0-2-3.47zM47.42 49.79a11.92 11.92 0 0 0-18.17 6.68 10.65 10.65 0 0 0 1.06 8.17 10.54 10.54 0 0 0 3.41 3.57 2 2 0 0 0 2.17-3.36 6.55 6.55 0 0 1-2.13-2.22 6.67 6.67 0 0 1-.65-5.12 7.92 7.92 0 0 1 12-4.46 6.55 6.55 0 0 1 .92.8 2 2 0 1 0 2.89-2.77 10.69 10.69 0 0 0-1.5-1.29zM47.55 19.6a2 2 0 0 0 1.87-2.71l-2.88-7.56a2 2 0 1 0-3.74 1.42l2.88 7.55a2 2 0 0 0 1.87 1.3z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
      <path
        d="M127.69 108.22c-2.75-4.31-26.95-42.22-30.31-45.51l-.7-.69c-3.17-3.12-6.75-6.66-11.6-6.66H85c-3.43 0-10.44.51-13.51.74L50.63 1.29A2 2 0 0 0 48.06.13L9.29 14.68a2 2 0 0 0-1.17 2.58l1.32 3.47a2 2 0 1 0 3.74-1.42l-.61-1.59 35-13.15 24 63.12h-.08a2 2 0 0 0-.26-.76L39.6 12.49a2 2 0 0 0-2.72-.73L1 32.36a2 2 0 0 0-.73 2.74l40.85 70.11a2 2 0 0 0 .2.26 2.06 2.06 0 0 0 .13.16 2 2 0 0 0 .23.18 1 1 0 0 0 .45.24 1.46 1.46 0 0 0 .72.15 2 2 0 0 0 .51-.07l.15-.06a2 2 0 0 0 .33-.14l8.62-5a6.94 6.94 0 0 0 3.12 7.32c1.48 1 13.64 7.43 17.13 7.43 2.17 0 6.43-1.84 8.91-3A30.23 30.23 0 0 0 102.38 128h.29a2 2 0 0 0 .29-4 26.18 26.18 0 0 1-18-13.46 45.22 45.22 0 0 1-2.06-4.86c-1.42-3.71-2.88-7.54-5.4-9.73-1.33-1.16-6.22-5-11.87-9.48l-8.08-6.4c-2.78-2.2-11.23-8.91-12.86-10.51a5.75 5.75 0 0 1-2-4 6 6 0 0 1 2-4c1.69-1.71 4.32-1.61 7.8.28L86 80a2 2 0 0 0 .94.24 2 2 0 0 0 1-3.76L77 70.58a2 2 0 0 0-.09-.32L73 60c3.39-.24 9.16-.62 12.05-.64 3.2 0 5.92 2.68 8.79 5.51l.71.7c2.3 2.25 19 28 29.73 44.8a2 2 0 1 0 3.37-2.15zm-49.64-4c.4 1 .79 2 1.16 2.92.26.68.51 1.33.77 2-2.95 1.37-6.19 2.63-7.27 2.64-1.92-.05-12.5-5.19-14.94-6.78a3 3 0 0 1-1.12-4 3.39 3.39 0 0 1 2.08-2c1.34 1 2.9 2 4.34 2.88 1.17.73 2.27 1.42 2.88 1.89 1.08.85 3.07 1.12 5.16 1.12a39.09 39.09 0 0 0 6.94-.69zm-1.86-3.69c-2.39.38-6.81.69-7.78.14-.78-.61-1.91-1.31-3.22-2.14s-2.73-1.71-3.92-2.59l5.73-3.3c3.9 3.08 7 5.51 7.92 6.36a7.39 7.39 0 0 1 1.28 1.52zM41.84 58.78a9.72 9.72 0 0 0-3.15 6.88 9.55 9.55 0 0 0 3.25 6.78c1.23 1.2 5.29 4.53 13.17 10.78l8.09 6.4.44.34-20.07 11.53L4.74 34.83l32.41-18.61 27.91 47.91-10.67-5.78c-6.52-3.53-10.39-1.72-12.55.43z"
        fill="#000000"
        opacity={1}
        data-original="#000000"
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
