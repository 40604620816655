import React from 'react'

export const WhatsApp = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <style>{".cls-3{stroke-width:0;fill:transparent}"}</style>
    </defs>
    <path
      d="M1.13 15.45V1.21h29.74v29.57H1.13V16.6h.04c.02.25.03.49.05.74.26 2.6 1.13 4.97 2.66 7.1.08.11.09.21.05.34-.57 1.69-1.14 3.37-1.7 5.06-.02.06-.04.13-.06.21.1-.03.17-.04.24-.07 1.74-.55 3.47-1.1 5.21-1.65.16-.05.27-.04.42.05 3.23 2.01 6.72 2.74 10.47 2.1 3.47-.59 6.38-2.23 8.68-4.89 2.82-3.26 3.99-7.08 3.53-11.35-.34-3.17-1.64-5.95-3.84-8.24C23.25 2.23 18.8.66 13.64 1.47 8.79 2.23 5.2 4.91 2.85 9.22c-1.05 1.93-1.53 4.04-1.68 6.22h-.04Z"
      className="cls-3"
    />
    <path
      d="M1.17 16.6h-.04v-1.16h.04v1.16Z"
      style={{
        fill: "#d5f3cd",
        strokeWidth: 0,
      }}
    />
    <path
      d="M1.17 16.6v-1.16c.14-2.19.62-4.29 1.68-6.22C5.2 4.91 8.78 2.24 13.64 1.47 18.8.66 23.25 2.23 26.87 6c2.2 2.3 3.51 5.07 3.84 8.24.45 4.28-.71 8.09-3.53 11.35-2.3 2.66-5.21 4.3-8.68 4.89-3.75.63-7.24-.1-10.47-2.1a.448.448 0 0 0-.42-.05c-1.73.56-3.47 1.1-5.21 1.65l-.24.07c.03-.09.04-.15.06-.21.57-1.69 1.13-3.37 1.7-5.06a.35.35 0 0 0-.05-.34c-1.53-2.13-2.4-4.5-2.66-7.1-.02-.25-.04-.49-.05-.74Zm6.92-4.33s.04 0 .05.01c0 .93.27 1.8.69 2.63.57 1.14 1.33 2.15 2.13 3.13 1.82 2.22 4.03 3.88 6.85 4.64 1.09.3 2.19.56 3.33.43.99-.11 1.79-.52 2.26-1.44.29-.58.43-1.2.43-1.85 0-.24-.07-.41-.3-.52-1.03-.5-2.06-1.02-3.09-1.5-.48-.23-.7-.12-1 .33-.25.36-.49.74-.79 1.05-.58.6-.84.53-1.4.3-2.14-.89-3.74-2.39-4.95-4.35a.594.594 0 0 1-.02-.64c.08-.14.16-.27.26-.4.2-.26.41-.51.62-.77.29-.35.36-.71.15-1.16-.42-.91-.78-1.85-1.17-2.77-.22-.52-.38-.61-.93-.59-1.39.06-2.39.77-2.82 2.07-.15.44-.2.92-.29 1.38Z"
      style={{
        strokeWidth: 0,
        fill: "#67d349",
      }}
    />
    <path
      d="M8.09 12.27c.09-.46.14-.94.29-1.38.44-1.3 1.43-2.01 2.82-2.07.55-.02.71.07.93.59.39.92.75 1.86 1.17 2.77.21.45.14.8-.15 1.16-.21.26-.42.51-.62.77-.1.12-.18.26-.26.4-.12.22-.11.43.02.64 1.21 1.95 2.81 3.45 4.95 4.35.56.23.82.3 1.4-.3.3-.31.55-.69.79-1.05.3-.45.52-.56 1-.33 1.04.49 2.06 1 3.09 1.5.22.11.3.28.3.52 0 .65-.14 1.27-.43 1.85-.46.92-1.27 1.33-2.26 1.44-1.14.13-2.24-.13-3.33-.43-2.81-.77-5.02-2.42-6.85-4.64-.8-.98-1.56-1.99-2.13-3.13-.41-.82-.68-1.69-.69-2.63-.02 0-.04 0-.05-.01Z"
      className="cls-3"
    />
  </svg>
    </>
  )
}
