import * as React from "react";
const AttractiveDeal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 100 100"
    viewBox="0 0 100 100"
    width={256}
    height={256}
    {...props}
  >
    <path
      d="M93.833,51.797c-0.223-1.472-0.927-2.638-2.036-3.372c-0.951-0.629-2.042-0.843-2.985-0.879 c0.013-0.774-0.167-1.616-0.786-2.313c-1.211-1.367-3.452-1.488-6.847-0.375c-5.055,1.658-7.279,4.74-8.902,6.99 c-0.842,1.167-1.414,1.92-2.016,2.242c-1.311-1.181-3.07-1.986-5.105-2.269c-3.313-0.46-7.184,0.193-10.927,0.824 c-5.453,0.919-10.601,1.788-12.272-1.046c-4.292-7.281-19.467-1.032-25.401,1.778v-1.017c0-0.829-0.671-1.5-1.5-1.5H8.437 c-0.829,0-1.5,0.671-1.5,1.5v19.185c0,0.829,0.671,1.5,1.5,1.5h6.618c0.804,0,1.454-0.633,1.493-1.427 c5.235,2.419,19.202,8.627,32.132,12.225c1.105,0.308,2.251,0.451,3.428,0.451c16.981,0,40.375-29.993,41.429-31.356 C93.787,52.615,93.894,52.203,93.833,51.797z M13.555,70.046H9.937V53.862h3.618V70.046z M49.483,80.954 c-13.967-3.886-29.22-10.895-32.928-12.641V56.715c5.785-2.877,19.89-8.552,22.817-3.591c2.719,4.613,9.144,3.528,15.355,2.481 c3.52-0.593,7.156-1.208,10.016-0.811c2.269,0.315,4.01,1.488,4.656,3.137c0.388,0.99,0.298,1.973-0.239,2.629 c-0.647,0.791-1.905,1.066-3.543,0.777c-7.313-1.297-24.27,4.532-24.988,4.78c-0.783,0.271-1.198,1.125-0.927,1.908 c0.215,0.62,0.796,1.01,1.417,1.01c0.163,0,0.328-0.027,0.491-0.083c4.687-1.622,18.045-5.618,23.485-4.661 c3.713,0.655,5.595-0.866,6.386-1.831c1.234-1.507,1.5-3.61,0.71-5.624c-0.043-0.111-0.105-0.212-0.154-0.32 c1.057-0.695,1.852-1.778,2.671-2.913c1.536-2.128,3.277-4.541,7.405-5.895c2.943-0.965,3.66-0.49,3.664-0.49 c0.099,0.165,0.011,0.846-0.226,1.369c-0.245,0.526-0.166,1.146,0.205,1.592s0.964,0.639,1.528,0.495 c0.376-0.097,1.965-0.337,2.856,0.253c0.182,0.12,0.415,0.32,0.577,0.739C87.188,56.087,63.54,84.868,49.483,80.954z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M54.408,47.853c8.866,0,16.079-7.213,16.079-16.079s-7.213-16.079-16.079-16.079s-16.079,7.213-16.079,16.079 S45.542,47.853,54.408,47.853z M54.408,18.694c7.212,0,13.079,5.867,13.079,13.079S61.62,44.853,54.408,44.853 s-13.079-5.867-13.079-13.079S47.196,18.694,54.408,18.694z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M52.208 29.078c2.194 0 3.979-1.785 3.979-3.979s-1.785-3.979-3.979-3.979-3.979 1.785-3.979 3.979S50.013 29.078 52.208 29.078zM52.208 24.119c.54 0 .979.439.979.979s-.439.979-.979.979-.979-.439-.979-.979S51.667 24.119 52.208 24.119zM57.758 33.676c-2.194 0-3.979 1.785-3.979 3.979s1.785 3.979 3.979 3.979 3.979-1.785 3.979-3.979S59.953 33.676 57.758 33.676zM57.758 38.635c-.54 0-.979-.439-.979-.979s.439-.979.979-.979.979.439.979.979S58.298 38.635 57.758 38.635zM47.53 39.896c.375 0 .75-.139 1.041-.419l13.755-13.246c.597-.575.615-1.524.04-2.121-.575-.597-1.524-.614-2.121-.04L46.489 37.315c-.597.575-.615 1.524-.04 2.121C46.744 39.742 47.137 39.896 47.53 39.896z"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
);
export default AttractiveDeal;
