import React from "react";

const Download = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      viewBox="0 0 500 500"
    >
      <g>
        <path className="_color" d="M280.43 100.28l-99.76 0c-14.72,0 -28.11,5.87 -37.87,15.4 -9.7,9.47 -15.88,22.58 -16.3,37.13l-0.02 190.8c0.05,14.69 5.9,28.02 15.39,37.71 9.55,9.76 22.77,15.93 37.39,16.24l142.09 0.02c14.79,-0.53 28.06,-6.88 37.62,-16.78 9.55,-9.89 15.42,-23.4 15.42,-38.22l0 -145.78 -35.42 0c-16.17,-0.08 -30.82,-6.67 -41.39,-17.27 -10.59,-10.6 -17.15,-25.25 -17.15,-41.36l0 -37.89zm-99.49 -26.46l110.36 0 2.36 -0.21 2.36 0.21 2.26 0c3.46,0.01 6.91,1.36 9.49,4.05l88.76 92.44c2.65,2.42 4.32,5.9 4.32,9.77l0 162.5c0,21.89 -8.69,41.85 -22.86,56.52 -14.15,14.67 -33.81,24.06 -55.7,24.84l-143.55 -0.02c-21.85,-0.46 -41.52,-9.62 -55.69,-24.1 -14.21,-14.53 -22.98,-34.39 -22.98,-56.21 7.34,-51.36 -7.96,-141.36 0.06,-191.11 0.51,-21.87 9.73,-41.55 24.27,-55.74 14.54,-14.2 34.43,-22.94 56.27,-22.94l0.27 0zm179.03 96.52l-53.08 -55.28 0 23.11c0,8.89 3.59,16.96 9.39,22.76 5.81,5.82 13.84,9.44 22.69,9.46l21 -0.05z" />
        <path className="_color" d="M256.88 319.66c0,7.3 -5.93,13.23 -13.23,13.23 -7.3,0 -13.23,-5.93 -13.23,-13.23l0 -106.54c0,-7.3 5.93,-13.23 13.23,-13.23 7.3,0 13.23,5.93 13.23,13.23l0 106.54zm18.82 -50.84c5.13,-5.16 13.49,-5.18 18.65,-0.05 5.16,5.13 5.18,13.49 0.05,18.65l-41.36 41.52c-5.13,5.16 -13.49,5.18 -18.65,0.05l-41.41 -41.59c-5.13,-5.16 -5.11,-13.52 0.05,-18.65 5.16,-5.13 13.52,-5.11 18.65,0.05l32.01 32.15 32.01 -32.13z" />
      </g>
    </svg>
  );
};

export default Download;
