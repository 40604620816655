import React, { useEffect, useState } from "react";
import WhyUs from "../components/WhyUs";
import { Test_details_logic } from "../components/Test_details_logic";
import { useParams } from "react-router-dom";


const Page = () => {
  const [category, setCategory] = useState("");
  const { slug } = useParams();


  if (!slug) {
    return <div>Loading...</div>; // Or some other fallback UI
  }

  return (
    <main className="d-flex flex-wrap float-start col-12">
      <section>
        <div className="container">
          <div className="web-container">
            <div className="col-12">
              <Test_details_logic Slug={slug}  />
            </div>
          </div>
        </div>
        <div className="pt-5">
          <WhyUs Title="WHY ASSURE PATHLABS?" />
        </div>
      </section>
    </main>
  );
};

export default Page;
